






































































































































































































































































































































































import { Component, Vue } from "vue-property-decorator";
import Axios from "axios";
import { BASE_API_URL } from "../config";
import { authHeader } from "../services/auth";
import suneditor from "suneditor";
import plugins from "suneditor/src/plugins";

@Component({
  components: {},
})
export default class CommonDeleteModal extends Vue {
  /* eslint-disable */
  public message = null;
  public aPIPath = null;
  public currentData = null;
  public reason = null;
  public taskId = null;
  public isDisabled = false;
  public isData = false;
  public isUpdate = false;
  public manufacturedHomeObj: any = {
    isIbtsRequired: false,
    remark: null,
    isEngineerRequired: false,
    processorMessage: null,
  };
  public loanId = null;
  public manufacturedHomeData: any = {};
  public disclosureNotFoundObj: any = {
    remarkNote: null,
  };

  public options = [
    { text: "No", value: false },
    { text: "Yes", value: true },
  ];
  public editor: any = null;
  public error: any = {
    message: null,
    show: false,
  };

  /**
   * Delete Confirmation
   */
  public async confirmationBox(body) {
    try {
      this.message = body.msg;
      this.aPIPath = body.apiPath;
      this.currentData = body.data;
      await this.$modal.show("confirmationModal");
    } catch (error) {
      console.log(error);
    }
  }

  /**
   * UserChoice
   */
  public async userChoice(userChoice: string) {
    try {
      if (userChoice === "NO") {
        await this.$snotify.info("No changes");
        this.$modal.hide("confirmationModal");
        this.$emit("call-mount-funtion");
      } else {
        const res = await Axios.post(
          BASE_API_URL + this.aPIPath,
          this.currentData,
          { headers: authHeader() }
        );
        // if (res.status === 201) {
        //   await this.$snotify.info(res.data.message);
        //   this.$modal.hide('confirmationModal');
        //   this.$emit('call-mount-funtion');
        // }
        if (res.status === 201) {
          await this.$snotify.success(res.data.message);
          this.$modal.hide("confirmationModal");
          this.$emit("call-mount-funtion");
        } else {
          this.$snotify.error(res.data.message);
          this.$modal.hide("confirmationModal");
          this.$emit("call-mount-funtion");
        }
      }
    } catch (error) {
      console.log(error);
    }
  }

  public openModal(body) {
    this.message = body.messgae;
    this.taskId = body.taskId;
    this.currentData = body.data;
    this.$modal.show("SendMailModal");
  }

  public async sendMailToLOAboutTask() {
    try {
      const response = await Axios.post(
        `${BASE_API_URL}processor/sendMailToLOAboutTask`,
        {
          reason: this.reason,
          taskId: this.taskId,
          loanId: this.$route.query.id,
          data: this.currentData,
        },
        {
          headers: authHeader(),
        }
      );
      if (response.status == 201) {
        this.$modal.hide("SendMailModal");
        this.reason = null;
        this.currentData = null;
        this.message = null;
        this.taskId = null;
        this.$snotify.success("Remark Added Successfully.");
        this.$emit("call-mount-funtion");
      }
    } catch (error) {
      console.log(error);
    }
  }

  public openManufacturedHomeModal(body) {
    this.manufacturedHomeObj.processorMessage = body.messgae;
    this.manufacturedHomeObj.isIbtsRequired = body.isIbtsRequired;
    this.manufacturedHomeObj.isEngineerRequired = body.isEngineerRequired;
    this.loanId = body.loanId;
    if (body.taskId) this.taskId = body.taskId;
    this.getPropertyManufacturedHomeRemarkInfo(body.loanId);
    this.$modal.show("notifyProcessorAboutManufacturedHome");
  }

  public cancelManufacturedHomeRemark() {
    this.$modal.hide("notifyProcessorAboutManufacturedHome");
    this.manufacturedHomeObj = {
      isIbtsRequired: false,
      remark: null,
      isEngineerRequired: false,
      processorMessage: null,
    };
    this.loanId = null;
    this.taskId = null;
  }

  public async addManufacturedHomeRemark() {
    this.$store.state.tmpcoLoader = true;
    try {
      if (!this.manufacturedHomeObj.remark) {
        this.$store.state.tmpcoLoader = false;
        return;
      }
      let myobj = {
        manufacturedHomeObj: this.manufacturedHomeObj,
        loanId: this.loanId,
        taskId: this.taskId,
        userType: this.$store.state.sessionObj.userType,
      };
      let response = await Axios.post(
        BASE_API_URL + "broker/notifyProcessorAboutManufacturedHomeRemark",
        myobj,
        { headers: authHeader() }
      );

      if (response.status === 201) {
        this.cancelManufacturedHomeRemark();
        this.$snotify.success("Remark Added Successfully");
        this.$emit("call-mount-funtion");
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public openDisclosureNotFoundModal(body) {
    this.error.show = false;
    this.error.message = "";
    this.disclosureNotFoundObj.disclosureData = body.disclosureData;
    this.loanId = body.loanId;
    this.isDisabled = body.isDisabled;
    this.isUpdate = body.isUpdate;
    setTimeout(async () => {
      this.editor = await suneditor.create("pageEditor", {
        plugins: {
          ...plugins,
        },
        width: "auto",
        height: "100px",
        buttonList: [["bold"], ["list"]],
      });
    }, 1);
    this.$modal.show("disclosureNotFound");
  }

  public async addRemark() {
    this.$store.state.tmpcoLoader = true;
    try {
      // if (!this.disclosureNotFoundObj.remarkNote) {
      //   this.$store.state.tmpcoLoader = false
      //   return;
      // }
      this.disclosureNotFoundObj.remarkNote = this.editor.getContents();
      if (
        this.disclosureNotFoundObj.remarkNote
          .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
          .trim() != ""
      ) {
        this.error.message = "";
      } else {
        this.error.show = true;
        this.error.message = "Please add a Remark";
      }
      let myobj = {
        remarkNote: this.disclosureNotFoundObj.remarkNote,
        loanId: this.loanId,
        userType: this.$store.state.sessionObj.userType,
        isUpdate: this.isUpdate,
      };
      if (
        this.disclosureNotFoundObj.remarkNote
          .replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, "")
          .trim() != ""
      ) {
        let response = await Axios.post(
          BASE_API_URL + "broker/notifyProcessorAboutDisclosureSigned",
          myobj,
          { headers: authHeader() }
        );

        if (response.status === 201) {
          this.$modal.hide("disclosureNotFound");
          this.isDisabled = false;
          this.$snotify.success("Remark Added Successfully");
          this.disclosureNotFoundObj.remarkNote = null;
          this.loanId = null;
          this.isUpdate = false;
          this.$emit("call-mount-funtion");
        }
      }
    } catch (error) {
      console.log(error);
    }
    this.$store.state.tmpcoLoader = false;
  }

  public async getPropertyManufacturedHomeRemarkInfo(loanId) {
    //this.callLoader(true, 1);
    try {
      let response = await Axios.get(
        BASE_API_URL +
          "broker/dashboard/getPropertyManufacturedHomeRemarkInfo/" +
          loanId,
        { headers: authHeader() }
      );
      if (response.status === 201) {
        this.manufacturedHomeData = JSON.parse(JSON.stringify(response.data));
      }
    } catch (error) {
      console.log(error);
    }
    //  this.callLoader(false, null);
  }
}
